import * as Dapp from '@elrondnetwork/dapp';
import { useHistory } from 'react-router-dom';
import { Pane, Heading, majorScale, StatusIndicator, Popover, Menu, Position, Strong, Text } from 'evergreen-ui';
import { shortenWalletAddress } from '../utils';
import { routes } from '../routes';

const MainLayout: React.FC = ({ children }) => {
  const { address } = Dapp.useContext();
  const dappLogout = Dapp.useLogout();
  const history = useHistory();

  const logOut = (e: React.SyntheticEvent<Element, Event>) => {
    e.preventDefault();
    dappLogout({ callbackUrl: `${window.location.origin}/` });
    history.push('/');
  };

  return (
    <Pane>
      <Dapp.Authenticate routes={routes} unlockRoute="/unlock">
        <Pane background="white" marginBottom={30} border="default">
          <Pane
            maxWidth={1200}
            paddingX={30}
            marginX="auto"
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            height={majorScale(8)}
          >
            <Pane display="flex">
              <Heading size={800} onClick={() => history.push('/')} cursor="pointer" display="flex" alignItems="center">
                {/* <img className="logo" src="/i/ofero-market-logo.svg" alt="" width="250" height="46" /> */}
                <picture>
                  <source media="(max-width: 579px)" sizes="48px" srcSet="/i/ofe-logo.svg 48w" />
                  <source media="(min-width: 580px)" sizes="180px" srcSet="/i/ofero-market-logo.svg 250w" />
                  <img className="logo" src="/i/ofe-logo.png" alt="Ofero Market" width="48" height="48" />
                </picture>
              </Heading>
            </Pane>
            {address ? (
              <Pane display="flex" alignItems="center">
                <Heading onClick={() => history.push('/dashboard')} cursor="pointer" marginRight={15}>
                  My Account
                </Heading>
                <Popover
                  position={Position.BOTTOM_RIGHT}
                  content={
                    <Menu>
                      <Menu.Group>
                        <Menu.Item onSelect={logOut}>Logout</Menu.Item>
                      </Menu.Group>
                    </Menu>
                  }
                >
                  <StatusIndicator color="success" title={address} cursor="pointer">
                    {shortenWalletAddress(address)}
                  </StatusIndicator>
                </Popover>
              </Pane>
            ) : (
              <Pane>
                <Strong onClick={() => history.push('/unlock')} cursor="pointer">
                  Login
                </Strong>
              </Pane>
            )}
          </Pane>
        </Pane>
        <Pane maxWidth={1200} paddingX={30} marginX="auto" width="100%" paddingBottom={8}>
          {children}
        </Pane>
      </Dapp.Authenticate>
      <Pane background="tint1" padding={24} marginBottom={8} textAlign="center">
        {/* <a href="https://ofero.network/#contact" className="underline">
          Contact
        </a>{' '}
        @ Ofero.Market {new Date().getFullYear()} */}
        <Text size={400}>
          For any questions regarding ofero.market please join the @oferonetwork Telegram channel at{' '}
          <a href="https://t.me/oferonetwork">https://t.me/oferonetwork</a>
        </Text>
      </Pane>
    </Pane>
  );
};

export default MainLayout;
