import * as Dapp from '@elrondnetwork/dapp';
import { Tokens } from './types';

export const walletConnectBridge: string = process.env.REACT_APP_WALLET_CONNECT_BRIDGE || '';
export const walletConnectDeepLink: string = process.env.REACT_APP_WALLET_CONNECT_DEEP_LINK || '';

export const network: Dapp.NetworkType = {
  id: process.env.REACT_APP_NETWORK_ID || '',
  name: process.env.REACT_APP_NETWORK_NAME || '',
  egldLabel: process.env.REACT_APP_NETWORK_EGLD_LABEL || '',
  walletAddress: process.env.REACT_APP_NETWORK_WALLET_ADDRESS,
  apiAddress: process.env.REACT_APP_NETWORK_API_ADDRESS,
  gatewayAddress: process.env.REACT_APP_NETWORK_GATEWAY_ADDRESS,
  explorerAddress: process.env.REACT_APP_NETWORK_EXPLORER_ADDRESS,
};

export const tokenList: Tokens = {
  OFE: {
    id: process.env.REACT_APP_TOKENS_OFE || '',
    symbol: 'OFE',
    price: process.env.REACT_APP_NETWORK_ID! === 'mainnet' ? 0 : 0.00633,
    decimals: 4,
  },
  USDC: {
    id: '',
    symbol: 'USDC',
    price: 1,
    decimals: 6,
  },
  WEGLD: {
    id: '',
    symbol: 'WEGLD',
    price: 0,
    decimals: 18,
  },
};
