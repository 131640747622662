import { Transaction, Address, TransactionPayload, GasLimit, Nonce } from '@elrondnetwork/erdjs';
import { stringToHex, decimalToHexTwosComplement } from './utils';

// export interface IssueNFTData {
//   tokenName: string;
//   tokenTicker: string;
// }

export interface AssignRolesData {
  tokenIdentifier: string;
  senderAddress: string;
  smartContractAddress: string;
}

// export interface CreateNFTData {
//   senderAddress: string;
//   tokenIdentifier: string;
//   nftName: string;
//   uri: string;
//   attributes?: string;
// }

export interface BuyNFTData {
  receiverAddress?: string;
  senderAddress: string;
  tokenIdentifier: string;
  amount: string;
  decimals: number;
  nonce: string;
  uri: string;
  attributes?: string;
}

// prettier-ignore
export const buyNFT = (data: BuyNFTData) => {
  //console.log('buyNFT.data', data);
  const tokenId = stringToHex(data.tokenIdentifier);
  //console.log('tokenid', tokenId);
  //const amount = (parseInt(data.amount, 10) * Math.pow(10, 4)).toString(16).toUpperCase();
  // let amount = (parseInt(data.amount, 10) * Math.pow(10, 4)).toString(16).toUpperCase();
  // if (amount.length % 2 === 1) {
  //   amount = '0' + amount;
  // }
  const amount = decimalToHexTwosComplement(parseInt(data.amount, 10) * Math.pow(10, data.decimals));
  const method = stringToHex('buyNft');
  //console.log('before send', `ESDTTransfer@${tokenId}@${amount}@${method}@${data.nonce}`);
  return new Transaction({
    receiver: new Address(data.receiverAddress), // contract
    sender: new Address(data.senderAddress),     // buyer
    data: new TransactionPayload(`ESDTTransfer@${tokenId}@${amount}@${method}@${data.nonce}`),
    gasLimit: new GasLimit(10000000), // 60000000
    // nonce: new Nonce(7),
  });
};

//
// 5000000000000000000
// ESDTTransfer@4f46452d303066396235@4563918244f40000

// export const issueNft = (data: IssueNFTData) =>
//   new Transaction({
//     receiver: new Address(smartContract),
//     data: new TransactionPayload(`issueNonFungible@${stringToHex(data.tokenName)}@${stringToHex(data.tokenTicker)}`),
//     gasLimit: new GasLimit(60000000),
//     nonce: new Nonce(7),
//   });

export const assignRoles = (data: AssignRolesData) =>
  new Transaction({
    receiver: new Address(data.smartContractAddress),
    data: new TransactionPayload(
      `setSpecialRole@${stringToHex(data.tokenIdentifier)}@${new Address(data.senderAddress).hex()}@45534454526f6c654e4654437265617465`
    ),
    gasLimit: new GasLimit(60000000),
    nonce: new Nonce(7),
  });

// // TODO: royalties
// export const createNFT = (data: CreateNFTData) =>
//   new Transaction({
//     receiver: new Address(data.senderAddress),
//     data: new TransactionPayload(
//       `ESDTNFTCreate@${stringToHex(data.tokenIdentifier)}@01@${stringToHex(data.nftName)}@00@@${stringToHex(
//         data.attributes || ''
//       )}@${stringToHex(data.uri)}`
//     ),
//     gasLimit: new GasLimit(60000000),
//     nonce: new Nonce(7),
//   });
