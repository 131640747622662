import { useContext, useEffect, useState, useRef } from 'react';
import * as Dapp from '@elrondnetwork/dapp';

import { tokenList, network, walletConnectBridge, walletConnectDeepLink } from './config';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Dashboard from './components/Dashboard/Dashboard';
import Unlock from './components/Unlock';
import WalletConnect from './components/WalletConnect';
import Ledger from './components/Ledger';
import Home from './components/Home';
import { TokensContext } from './Context';
import { Tokens, MexToken } from './types';

function App() {
  const tokens = useContext(TokensContext);
  const [pendingTokens, setPendingTokens] = useState(false);

  const mounted = useRef(true);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    setPendingTokens(true);

    const fetchEsdtTokens = async () => {
      try {
        // prettier-ignore
        //await new Promise<void>((resolve) => setTimeout(() => { resolve(); }, 5000));
        const response = await fetch(`${network.apiAddress}/mex/tokens`, { signal });
        const data: MexToken[] = await response.json();
        if (data) {
          data.forEach((token: MexToken) => {
            if (token.symbol && tokens.hasOwnProperty(token.symbol) && token.price) {
              tokens[token.symbol as keyof Tokens].price = token.price;
              tokens[token.symbol as keyof Tokens].id = token.id;
            }
          });
          //console.log('data', data);
        }
      } catch (error) {
        console.error(error, pendingTokens);
      } finally {
        setPendingTokens(false);
      }
    };
    if (network && tokenList) {
      fetchEsdtTokens();
    }

    return () => {
      mounted.current = false;
    };
  }, [network, tokenList]);

  return (
    <Dapp.Context
      config={{
        network,
        walletConnectBridge,
        walletConnectDeepLink,
      }}
    >
      <TokensContext.Provider value={tokens}>
        <Router>
          <Switch>
            <Route path="/unlock" exact>
              <Unlock />
            </Route>
            <Route path="/ledger" exact>
              <Ledger />
            </Route>
            <Route path="/walletconnect" exact>
              <WalletConnect />
            </Route>
            <Route path="/dashboard" component={Dashboard} exact />
            <Route path="/" component={Home} exact />
          </Switch>
        </Router>
      </TokensContext.Provider>
    </Dapp.Context>
  );
}

export default App;
