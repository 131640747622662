import React, { useContext, useEffect, useState, useRef } from 'react';
import * as Dapp from '@elrondnetwork/dapp';
import MainLayout from './MainLayout';
//import { useHistory } from 'react-router-dom';
import { Pane, Button, Paragraph, Heading, Link, Text, Spinner, Strong, Dialog } from 'evergreen-ui';
import { network } from '../config';
import { Tokens, Contract } from '../types';
import { TokensContext, ContractsContext } from '../Context';

import { buyNFT } from '../transactions';

const Home: React.FC = () => {
  const tokens = useContext(TokensContext);
  const contracts = useContext(ContractsContext);
  const { address } = Dapp.useContext();
  const sendTransaction = Dapp.useSendTransaction();
  const [forceUpdate, setForceUpdate] = useState(-1);

  const [pending, setPending] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  //const [contracts, setContracts] = useState<Contract[]>([]);
  //const history = useHistory();
  // const unlock = () => {
  //   history.push('/unlock');
  // };

  const mounted = useRef(true);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    setPending(true);
    const fetchContracts = async () => {
      try {
        // prettier-ignore
        //await new Promise<void>((resolve) => setTimeout(() => { resolve(); }, 5000));
        const response = await fetch(`/items-${network.id}.json`, { signal });
        const data: Contract[] = await response.json();
        if (data) {
          if (mounted.current) {
            //setForceUpdate(0);
            //contracts.length = 0;
            //console.log('reset');
          }
          contracts.length = 0;
          contracts.push(...data);
        }
        //console.log('home use effect');
        // prettier-ignore
        // await new Promise<void>((resolve) => setTimeout(() => { resolve(); }, 5000));

        contracts.forEach((contract: any, i: number) => {
          if (!contract.hasOwnProperty('identifier')) {
            contract.identifier = "";
          }
          if (!contract.identifier) {
            if (!contract.hasOwnProperty('stock')) {
              contract.stock = null;
            }
            if (!contract.hasOwnProperty('collectionIds')) {
              contract.collectionIds = [];
            }

            // prettier-ignore
            Promise.all([
              fetch(`${network.apiAddress}/accounts/${contract.address}/nfts/count`, { signal }),
              fetch(`${network.apiAddress}/accounts/${contract.address}/nfts?from=0&size=20&collection=${contract.collection}`, { signal }),
            ])
              .then((results) => Promise.all(results.map((r) => r.json())))
              .then((results) => {
                contract.stock = results[0];
                const data = results[1];
                if (data && data.length) {
                  data.forEach((nft: any) => {
                    contract.collectionIds.push(nft.identifier);
                  });                  
                }
                setForceUpdate(i);
              })
              .catch((err) => {
                console.error(err);
              });
          }else{
            if (!contract.hasOwnProperty('stock')) {
              contract.stock = null;
            }
             fetch(`${network.apiAddress}/nfts/${contract.identifier}`, { signal })
             .then(response => response.json())
             .then(data => {
              if(data){
                contract.stock = data.owner === contract.address ? 1 : 0;
                setForceUpdate(i);
              }
             })
            
          }

        });
      } catch (error) {
        console.log(error);
      } finally {
        setPending(false);
      }
    };
    fetchContracts();
    if (network) {
    }
    return () => {
      mounted.current = false;
      contracts.length = 0;
    };
  }, [network]);

  const buyNftToken = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!address) {
      setLoginOpen(true);
      return;
    }

    const collection = e.currentTarget.getAttribute('data-collection');
    const tokenIdentifier = e.currentTarget.getAttribute('data-token-identifier');
    let identifier = e.currentTarget.getAttribute('data-identifier');

    const contract = identifier
      ? contracts.find((c) => c.collection === collection && c.identifier === identifier)
      : contracts.find((c) => c.collection === collection && c.collection === collection);

    if (contract === undefined) {
      alert('The collection has already been sold');
      return;
    }
    //console.log('test', contract || '');
    if (!identifier) {
      if (!contract.collectionIds || !Array.isArray(contract.collectionIds) || contract.collectionIds.length === 0) {
        alert('The collection has already been sold');
        return;
      }

      identifier = contract.collectionIds[Math.floor(Math.random() * contract.collectionIds.length)];
    }
    //console.log(identifier.substring(identifier.lastIndexOf('-') + 1));

    if (!address) {
      alert('You nedd to authentificate first');
      return;
    }
    // if (contract.address && identifier && nftName) {
    const tx = buyNFT({
      senderAddress: address,
      receiverAddress: contract.address, // contract
      tokenIdentifier: tokenIdentifier!, // buyer
      amount: contract.value!,
      decimals: tokens[tokenIdentifier?.substring(0, tokenIdentifier?.indexOf('-')) as keyof typeof tokens].decimals,
      nonce: identifier.substring(identifier.lastIndexOf('-') + 1),
      uri: contract.media,
    });
    sendTransaction({
      transaction: tx,
      callbackRoute: '/dashboard',
    });
    // }
  };

  return (
    <MainLayout>
      <Dialog isShown={loginOpen} hasFooter={false} title="" onCloseComplete={() => setLoginOpen(false)} preventBodyScrolling>
        <Dapp.Pages.Unlock
          title="Login"
          callbackRoute="/"
          lead="Please select your login method:"
          ledgerRoute="/ledger"
          walletConnectRoute="/walletconnect"
        />
      </Dialog>
      <Pane backgroundColor="white" elevation={1} padding={30}>
        <Pane position="absolute" className="hidden">
          State: {forceUpdate}
        </Pane>
        <Heading is="h1" marginBottom={10} textAlign="center" maxWidth={800} marginLeft="auto" marginRight="auto" width="100%">
          Choose an NFT to buy
        </Heading>

        {pending && contracts.length === 0 && (
          <Pane textAlign="center" minHeight={36}>
            <Spinner marginX="auto" marginY="auto" />
          </Pane>
        )}

        <Pane display="flex" flexWrap="wrap">
          {contracts.map((nft: any, index: number) => (
            <Pane className="nftPanel" backgroundColor="white" margin={10} padding={20} elevation={1} key={index}>
              {nft.stock === 0 && <Pane className={`badge soldout`}>EXHAUSTED</Pane>}
              <Heading is="h2" size={600}>
                {nft.name}
              </Heading>
              <Pane>
                <Text fontSize={12}>Collection: </Text>
                <Link href={`${network.explorerAddress}collections/${nft.collection}`}>
                  <Text color="gray800" fontSize={12} textDecoration="underline">
                    {nft.collection}
                  </Text>
                </Link>
              </Pane>
              <Pane marginBottom={10}>
                {nft.identifier ? (
                  <Pane>
                    <Text fontSize={12}>Id: </Text>
                    <Link href={`${network.explorerAddress}nfts/${nft.identifier}`}>
                      <Text color="gray800" fontSize={12} textDecoration="underline">
                        {nft.identifier}
                      </Text>
                    </Link>
                  </Pane>
                ) : (
                  <Pane>
                    <Text fontSize={12}>Available: </Text>
                    <Text color="gray800" fontSize={12}>
                      {nft.stock}
                    </Text>
                    {nft.stock === null && <Spinner size={14} className="inline-block" />}
                  </Pane>
                )}
              </Pane>
              {nft.media && (
                <Pane is="a" href={nft.media}>
                  {/* <Pane is="img" src={nft.media} maxWidth="100%" width={600} height={600} /> */}
                  <img src={nft.media} className="max-w-full h-auto" width="600" height="600" alt="" />
                </Pane>
              )}
              <Pane display="flex" justifyContent="space-between;">
                <Pane marginTop={8}>
                  <Strong color="blue600" fontSize={18} fontWeight={600}>
                    {nft.value}
                  </Strong>{' '}
                  <Strong color="blue600" fontSize={15} fontWeight={600}>
                    {nft.tokenSymbol}/NFT
                  </Strong>{' '}
                  <br />
                  {nft.tokenSymbol ? (
                    <>
                      {!tokens[nft.tokenSymbol as keyof typeof tokens].price ? (
                        <Text className="pulsate rounded block">&#160;</Text>
                      ) : (
                        <>
                          <Strong className="inline-block" color="gray800" fontSize={15} fontWeight={600}>
                            {Math.round((tokens[nft.tokenSymbol as keyof typeof tokens].price * nft.value + Number.EPSILON) * 10000) /
                              10000}
                          </Strong>
                          <Text className="inline-block" marginLeft={3} color="gray800" fontSize={13} fontWeight={600}>
                            USDC
                          </Text>
                        </>
                      )}
                    </>
                  ) : null}
                </Pane>
                <Pane marginTop={10} textAlign="right">
                  <Button
                    onClick={buyNftToken}
                    data-collection={nft.collection}
                    data-identifier={nft.identifier}
                    data-token-identifier={tokens[nft.tokenSymbol as keyof Tokens]?.id}
                    appearance="primary"
                    isLoading={!tokens[nft.tokenSymbol as keyof Tokens]?.id}
                    disabled={!nft.stock}
                  >
                    Buy NFT
                  </Button>
                </Pane>
              </Pane>
              <Paragraph marginTop={8} paddingTop={5} borderTop="1px solid #ccc">
                {nft.description}
              </Paragraph>
            </Pane>
          ))}
          {!pending && contracts.length === 0 && <Text>There are no NFTs yet.</Text>}
        </Pane>
      </Pane>
    </MainLayout>
  );
};

export default Home;
