export enum TransactionType {
  ISSUANCE,
  ROLES,
  CREATE,
  TRANSFER,
  ERROR,
}

export enum ChainID {
  TESTNET = 'T',
  DEVNET = 'D',
  MAINNET = 1,
}

export interface Token {
  id: string;
  symbol: string;
  price: number;
  decimals: number;
}

export interface Tokens {
  OFE: Token;
  USDC: Token;
  WEGLD: Token;
}

export interface Contract {
  caption: string;
  description: string;
  name: string;
  collection: string;
  identifier?: string;
  address: string;
  tokenSymbol: string;
  value: string;
  media: string;
  collectionIds?: string[];
}

export interface MexToken {
  id: string;
  symbol: string;
  name?: string;
  price: number;
}
